<script src="https://js.stripe.com/v3/"></script>
<template>
  <div class="container" id="directPayment" v-loading.fullscreen.lock="loading" :element-loading-text="loadingText">
    {{ validationProcessCalled }}
    <el-row :gutter="24" v-if="this.paymentStatus && (this.paymentStatus.paymentIntent || this.paymentStatus.entity)">
      <el-col v-if="status == 'succeeded'" class="successPrint">
        <div class="container">
          <div class="printer-top"></div>
            
          <div class="paper-container">
            <div class="printer-bottom"></div>
        
            <div class="paper">
              <div class="main-contents">
                <div class="success-icon">&#10004;</div>
                <div class="success-title">
                  Payment Complete
                </div>
                <div class="receipt-number">
                  {{ this.payload.template_data.payId }}
                </div>
                <div class="success-description">
                  {{ statusMessage }}
                </div>

                <div class="order-details">
                  <div class="order-number-label">Order Number</div>
                  <div class="order-number">
                    {{ this.payload.template_data.payment_id }}
                  </div>

                </div>
                <div class="order-footer">Thank you!</div>
              </div>
              <div class="jagged-edge"></div>
            </div>
          </div>
        </div>
        <!-- <el-result icon="success" :title="statusTitle" :subTitle="statusMessage">
          <template slot="extra">
          </template>
        </el-result> -->
      </el-col>
      <el-col :sm="24" :lg="24" v-else-if="status == 'processing'">
        <el-result icon="warning" :title="statusTitle" :subTitle="statusMessage">
          <template slot="extra">
            <!-- <el-button type="warning" size="medium">view Invoice</el-button> -->
          </template>
        </el-result>
      </el-col>
      <el-col :sm="24" :lg="24" v-else>
        <el-result icon="error" :title="statusTitle" :subTitle="statusMessage">
          <template slot="extra">

            <el-button v-if="statusTitle" type="danger" size="medium" @click="goto">Try Again</el-button>
          </template>
        </el-result>
      </el-col>
    </el-row>
    <el-row :gutter="24" v-else>
      <el-col :sm="24" :lg="24" v-if="statusTitle">
        <el-result icon="error" :title="statusTitle" :subTitle="statusMessage">
          <template slot="extra">
            <el-button type="danger" size="medium" @click="goto">Try Again</el-button>
          </template>
        </el-result>
      </el-col>
    </el-row>
    <!-- <el-row v-else>
      <el-col>
           {{ validationProcessCalled }}
      </el-col>
    </el-row> -->
  </div>
</template>

<script>
  import {
    mapGetters
  } from "vuex";
  import {
    verifyPaymentLink
  } from "../../utils/swirepay/api.js";
  export default {
    name: 'update-checkout-status',
    data() {
      return {
        stripe: null,
        emailAddress: '',
        elements: null,
        paymentInitData: {},
        totalAmount: 0,
        paymentStatus: {},
        statusTitle: '',
        statusMessage: '',
        status: '',
        paymentGatewayDetails: {},
        payload: {},
        loading: false,
        loadingText: 'Loading....!',
      }
    },
    async mounted() {
      this.checkStatus();
    },
    computed: {
      ...mapGetters("paymentGateway", ["getPlansData", "getActivePaymentSystems", "getPaymentGatewayInfo", "getUpdateSystemInfo", "getPayment", "getOrderInfo", "getPGDetails", "getPaymentStatus"]),
      validationProcessCalled() {
        this.loading = true;
        setTimeout(() => {
          this.loadingText = "Please wait.Processing your request........"
        }, 2000);
        return ''
      }
    },
    methods: {

      goto() {
        this.$router.push({
          name: 'subscription',
          params: {
            key: Math.random()
          },
        });
      },

      //fetching payment gateway cred
      async getSessionInfo(sessionId, txnAcId) {
        await this.$store.dispatch("paymentGateway/fetchInitSystemsDataByPgId", sessionId);
        if (this.getPGDetails && this.getPGDetails.cred_details) {
          let paymentSystem = this.getPGDetails.cred_details.filter(
            (e) => e._id == txnAcId
          );
          let bytes = await this.$CryptoJS.AES.decrypt(paymentSystem[0].crypto, paymentSystem[0].ivKey);
          let decryptedData = JSON.parse(bytes.toString(this.$CryptoJS.enc.Utf8));
          return {
            ...paymentSystem[0],
            crypto: decryptedData
          };
        }
      },
      async checkStatus() {
        try {
          const SessionId = new URLSearchParams(window.location.search).get("pgid");
          const TxnAcId = new URLSearchParams(window.location.search).get("txnAcId");
          if (SessionId) {
            this.paymentGatewayDetails = await this.getSessionInfo(SessionId, TxnAcId);
            if (this.paymentGatewayDetails.pgType === 'SWIREPAY') {
              const paymentId = new URLSearchParams(window.location.search).get("sp-payment-link");
              const declineStatus = new URLSearchParams(window.location.search).get("decline");
              this.checkSwirepayPaymentStatus(paymentId, this.paymentGatewayDetails.pgType, declineStatus);
            } else if (this.paymentGatewayDetails.pgType === 'STRIPE') {
              this.stripe = Stripe(this.paymentGatewayDetails.crypto.publicKey) // add your stripe key
              const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
              await this.checkStripe(clientSecret)
            }
          }
        } catch (e) {
          console.log("checkStatus",e)
        }
      },

      async checkSwirepayPaymentStatus(paymentId, pgType, declineStatus = false) {

        if (declineStatus) {
          this.status = ''
          this.statusTitle = "Declined";
          this.statusMessage = "You have canceled this  payment sessions."
          this.loading = false;
          this.loadingText = "";
        }
        if (paymentId) {
          this.paymentStatus = await verifyPaymentLink(this.paymentGatewayDetails.crypto.apiKey, paymentId, true)

          if (this.paymentStatus.entity.paymentSession.status === "SUCCEEDED") {
            this.statusTitle = "Success";
            this.statusMessage = "Payment succeeded!";
            // this.txnNo=this.paymentIdDetails.entity.paymentSession.receiptNumber;
            this.payload = this.paymentStatus.entity.meta;
            this.payload.template_data.paymentType = "ONLINE";
            this.payload.template_data.pgName = "SwirePay";
            this.payload.template_data.payId = this.paymentStatus.entity.paymentSession.receiptNumber;
            this.payload.template_data.pay_secret = paymentId;
            this.status = 'succeeded'
            this.updateStatus();
          } else {
            this.statusTitle = "Failed";
            this.statusMessage = "Payment Not Done!";
          }
          // else if(this.paymentIdDetails.entity.paymentSession.status=== "FAILURE")
          // {
          //   this.statusTitle= "Failed";
          //   this.statusMessage="Payment Not Done!";
          // }
        }

      },
      async checkStripe(clientSecret) {
        if (!clientSecret) {
          return;
        }
        this.paymentStatus = await this.stripe.retrievePaymentIntent(clientSecret);
        switch (this.paymentStatus.paymentIntent.status) {
          case "succeeded":
            this.payload = JSON.parse(this.paymentStatus.paymentIntent.description);
            this.payload.template_data.paymentType = "ONLINE";
            this.payload.template_data.pgName = "Stripe";
            this.payload.template_data.payId = this.paymentStatus.paymentIntent.id;
            this.payload.template_data.pay_secret = this.paymentStatus.paymentIntent.client_secret;
            this.updateStatus();
            this.statusTitle = "Success";
            this.statusMessage = "Payment succeeded!";
            break;
          case "processing":
            this.statusTitle = "Processing";
            this.statusMessage = "Your payment is processing."
            this.loading = false;
            this.loadingText = "";
            break;
          case "requires_payment_method":
            this.statusTitle = "Failed";
            this.statusMessage = "Your payment was not successful, please try again."
            this.loading = false;
            this.loadingText = "";
            break;
          default:
            this.statusTitle = "Failed";
            this.statusMessage = "Your payment was Failed"
            this.loading = false;
            this.loadingText = "";
            break;
        }
        this.status = this.paymentStatus.paymentIntent.status
      },
      async updateStatus() {

        // paymentStatusStore
        await this.$store.dispatch("paymentGateway/paymentStatusStore", this.payload);
        setTimeout(() => {
          this.loadingText = "Do not do any action.Payment status is updating.......!   "
          setTimeout(() => {
            this.loadingText = "";
            this.loading = false;
          }, 2000);
        }, 2000);
        if (this.getPaymentStatus) {
          setTimeout(() => {
            this.loading = true;
            this.loadingText = "Please wait.... We will redirecting to subscription info"
            setTimeout(() => {
              this.loadingText = "";
              this.loading = false;

              this.$router.push({
                path: "/profile/subscription"
              });
            }, 2000);
          }, 2000);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  #directPayment {

    form {
      width: 30vw;
      min-width: 500px;
      align-self: center;
      box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
        0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
      border-radius: 7px;
      padding: 40px;
    }

    .hidden {
      display: none;
    }

    #payment-message {
      color: rgb(105, 115, 134);
      font-size: 16px;
      line-height: 20px;
      padding-top: 12px;
      text-align: center;
    }

    #payment-element {
      margin-bottom: 24px;
    }

    /* Buttons and links */
    button {
      background: #5469d4;
      font-family: Arial, sans-serif;
      color: #ffffff;
      border-radius: 4px;
      border: 0;
      padding: 12px 16px;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      display: block;
      transition: all 0.2s ease;
      box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
      width: 100%;
    }

    button:hover {
      filter: contrast(115%);
    }

    button:disabled {
      opacity: 0.5;
      cursor: default;
    }

    /* spinner/processing state, errors */
    .spinner,
    .spinner:before,
    .spinner:after {
      border-radius: 50%;
    }

    .spinner {
      color: #ffffff;
      font-size: 22px;
      text-indent: -99999px;
      margin: 0px auto;
      position: relative;
      width: 20px;
      height: 20px;
      box-shadow: inset 0 0 0 2px;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
    }

    .spinner:before,
    .spinner:after {
      position: absolute;
      content: "";
    }

    .spinner:before {
      width: 10.4px;
      height: 20.4px;
      background: #5469d4;
      border-radius: 20.4px 0 0 20.4px;
      top: -0.2px;
      left: -0.2px;
      -webkit-transform-origin: 10.4px 10.2px;
      transform-origin: 10.4px 10.2px;
      -webkit-animation: loading 2s infinite ease 1.5s;
      animation: loading 2s infinite ease 1.5s;
    }

    .spinner:after {
      width: 10.4px;
      height: 10.2px;
      background: #5469d4;
      border-radius: 0 10.2px 10.2px 0;
      top: -0.1px;
      left: 10.2px;
      -webkit-transform-origin: 0px 10.2px;
      transform-origin: 0px 10.2px;
      -webkit-animation: loading 2s infinite ease;
      animation: loading 2s infinite ease;
    }

    @-webkit-keyframes loading {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    @keyframes loading {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    @media only screen and (max-width: 600px) {
      form {
        width: 80vw;
        min-width: initial;
      }
    }

    .successPrint {
      //background-color: #cccccc40;
      border-radius: 5px;
      box-shadow: 0 0 5px rgba(204, 204, 204, 0.2509803922);
      border: 1px solid rgba(204, 204, 204, 0.2509803922);
      background-color: rgba(204, 204, 204, 0.2509803922);

      .container {
        width: 50%;
        min-width: 380px;
        min-width: 720px;
        margin: 30px auto;
        overflow: hidden;
      }

      .printer-top {
        z-index: 1;
        border: 6px solid #666666;
        height: 6px;
        border-bottom: 0;
        border-radius: 6px 6px 0 0;
        background: #333333;
      }

      .printer-bottom {
        z-index: 0;
        border: 6px solid #666666;
        height: 6px;
        border-top: 0;
        border-radius: 0 0 6px 6px;
        background: #333333;
      }

      .paper-container {
        position: relative;
        overflow: hidden;
        height: 467px;
      }

      .paper {
        background: #ffffff;
        //height: 447px;
        //position: absolute;
        z-index: 2;
        margin: 0 12px;
        margin-top: -12px;
        animation: print 1800ms cubic-bezier(0.68, -0.55, 0.265, 0.9);
        -moz-animation: print 1800ms cubic-bezier(0.68, -0.55, 0.265, 0.9);
      }

      .main-contents {
        margin: 0 12px;
        padding: 24px;
      }

      // Paper Jagged Edge
      .jagged-edge {
        position: relative;
        //height: 20px;
        //width: 100%;
        //margin-top: -1px;
      }

      .jagged-edge:after {
        content: "";
        display: block;
        position: absolute;
        //bottom: 20px;
        left: 0;
        right: 0;
        height: 20px;
        background: linear-gradient(45deg, transparent 33.333%, #ffffff 33.333%, #ffffff 66.667%, transparent 66.667%), linear-gradient(-45deg, transparent 33.333%, #ffffff 33.333%, #ffffff 66.667%, transparent 66.667%);
        background-size: 16px 40px;
        background-position: 0 -20px;
      }

      .success-icon {
        text-align: center;
        font-size: 48px;
        height: 72px;
        background: #359d00;
        border-radius: 50%;
        width: 72px;
        height: 72px;
        margin: 16px auto;
        color: #fff;
      }

      .success-title {
        font-size: 22px;
        text-align: center;
        color: #666;
        font-weight: bold;
        margin-bottom: 16px;
      }

      .success-description {
        font-size: 15px;
        line-height: 21px;
        color: #999;
        text-align: center;
        margin-bottom: 24px;
      }

      .receipt-number {
        text-align: center;
        color: #333;
        font-weight: bold;
      }

      .order-details {
        text-align: center;
        color: #333;
        font-weight: bold;

        .order-number-label {
          font-size: 18px;
          margin-bottom: 8px;
        }

        .order-number {
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          line-height: 48px;
          //font-size: 48px;
          padding: 8px 0;
          margin-bottom: 24px;
        }
      }

      .order-footer {
        text-align: center;
        line-height: 18px;
        font-size: 18px;
        margin-bottom: 8px;
        font-weight: bold;
        color: #999;
      }

      @keyframes print {
        0% {
          transform: translateY(-90%);
        }

        100% {
          transform: translateY(0%);
        }
      }

      @-webkit-keyframes print {
        0% {
          -webkit-transform: translateY(-90%);
        }

        100% {
          -webkit-transform: translateY(0%);
        }
      }

      @-moz-keyframes print {
        0% {
          -moz-transform: translateY(-90%);
        }

        100% {
          -moz-transform: translateY(0%);
        }
      }

      @-ms-keyframes print {
        0% {
          -ms-transform: translateY(-90%);
        }

        100% {
          -ms-transform: translateY(0%);
        }
      }
    }
  }
</style>